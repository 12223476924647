import { createMutation } from 'react-query-kit';

import { client } from '../api.js';

export interface DeleteProjectMutationVariables {
  organizationId: string;
  projectId: string;
}

export const useDeleteProject = createMutation<any, DeleteProjectMutationVariables>(
{
  mutationFn: async (vars) => {
    const proj = await client.delete(`/v1/organizations/${vars.organizationId}/projects/${vars.projectId}`);
    return proj.data;
  },
})
