import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline';

import Logo from '../logo.js';

interface HeaderProps {
  sidebarOpen: boolean;
  setSidebarOpen: (value: boolean) => void;
}

export default function Header(props: HeaderProps) {
  const MenuIcon = props.sidebarOpen ? XMarkIcon : Bars3Icon;
  return (
    <header className="sticky top-0 z-10 mb-[-1px] h-min border-b border-gray-500/70 backdrop-blur-sm lg:col-span-3 lg:col-start-1">
      <button
        type="button"
        className="absolute top-0 z-50 flex h-header p-5 lg:hidden"
        onClick={() => props.setSidebarOpen(!props.sidebarOpen)}
      >
        <span className="sr-only">Open sidebar</span>
        <MenuIcon className="h-6 w-6" aria-hidden="true" />
      </button>
      <div className="mx-auto flex h-header justify-between px-4 py-2 sm:px-6 lg:px-8">
        <div className="mx-auto lg:mx-0 ">
          <Logo type="full" className="h-full" />
        </div>
        <div className="absolute right-0 top-0 z-50 flex h-header">
          <button type="button" className="px-3 py-5 lg:px-5">
            <span className="sr-only">View notifications</span>
            <BellIcon className="h-6 w-6" aria-hidden="true" />
          </button>
          <a href="/api/auth/logout" className="px-3 py-5 lg:px-5">
            <span className="sr-only">Your profile</span>
            <img
              className="-mt-1 h-8 w-8 rounded-full"
              src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              alt=""
            />
          </a>
        </div>
      </div>
    </header>
  );
}
