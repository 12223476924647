import type { Prisma } from '@prisma/client';
import type { UseMutateAsyncFunction } from '@tanstack/react-query';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import ReactQuill from 'react-quill';
import { UpdateTaskMutationVariables } from '../../hooks/useUpdateTask/index.js';
import TextInput from './text-input.js';
import ToggleInput from './toggle-input.js';
import ComboBoxInput from './combobox-input.js';

interface TaskDetailFormProps {
  project: Prisma.ProjectGetPayload<{}>;
  task: Prisma.TaskGetPayload<{ include: { assignee: true } }>;
  users: Prisma.OrganizationUserGetPayload<{ include: { user: true } }>[];
  onSubmit: UseMutateAsyncFunction<
    { task: TaskDetailFormProps['task'] },
    unknown,
    UpdateTaskMutationVariables
  >;
}

interface IFormInput {
  name?: string;
  completed?: boolean;
  assigneeId: string | null;
  textOutput: string | null;
}

const taskToDefaultValues = (
  task: TaskDetailFormProps['task']
): IFormInput => ({
  name: task.name,
  completed: task.completed,
  assigneeId: task.assigneeId,
  textOutput: task.textOutput,
});

export default function TaskDetailForm(props: TaskDetailFormProps) {
  const formMethods = useForm<IFormInput>({
    mode: 'onChange',
    defaultValues: taskToDefaultValues(props.task),
  });
  const onSubmitInternal: SubmitHandler<IFormInput> = async (input) => {
    const retData = await props.onSubmit({
      organizationId: props.project.organizationId,
      projectId: props.project.id,
      taskId: props.task.id,
      patch: input,
    });
    formMethods.reset(taskToDefaultValues(retData.task));
  };
  return (
    <FormProvider {...formMethods}>
      <form
        className="grid grid-cols-1 gap-y-4"
        onSubmit={formMethods.handleSubmit(onSubmitInternal)}
      >
        <div className="grid gap-y-4">
          <TextInput
            name="name"
            label="Task Name"
            options={{ required: true }}
          />
          <ToggleInput label="Completed" name="completed" />

          <ComboBoxInput
            name="assigneeId"
            label="Owner/Assignee"
            items={props.users.map(({ user }) => ({
              id: user.id,
              label: `${user.firstName} ${user.lastName}`,
            }))}
            options={{ required: false }}
          />
        </div>
        <Controller
          name="textOutput"
          control={formMethods.control}
          // rules={{
          //   required: 'Please enter task description',
          // }}
          render={({ field }) => (
            <ReactQuill
              theme="snow"
              className="col-span-2 mb-8 min-h-[50vh] bg-slate-800 pb-10"
              {...field}
              value={field.value ?? undefined}
            />
          )}
        />
        <button
          type="submit"
          className="col-span-2 rounded-md bg-green-800 p-2 disabled:bg-gray-700"
          disabled={
            !formMethods.formState.isDirty || formMethods.formState.isSubmitting
          }
        >
          Save
        </button>
      </form>
    </FormProvider>
  );
}
