import { Switch } from '@headlessui/react';
import type { RegisterOptions } from 'react-hook-form';
import { useController, useFormContext } from 'react-hook-form';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

interface ToggleInputProps {
  name: string;
  label: string;
  options?: RegisterOptions;
  className?: string;
}

export default function ToggleInput(props: ToggleInputProps) {
  // console.log(
  //   'ToggleInput',
  //   props.name,
  //   JSON.stringify(props),
  //   Object.keys(props)
  // );
  const methods = useFormContext();
  const { field } = useController({
    name: props.name,
    control: methods.control,
    rules: props.options,
  });
  return (
    // <Controller
    //   control={methods.control}
    //   name={props.name}
    //   rules={props.options}
    //   render={({ field }) => (
    <Switch.Group as="div" className={props.className}>
      <span className="flex grow flex-col">
        <Switch.Label as="span" className="font-medium leading-6" passive>
          {props.label}
        </Switch.Label>
      </span>
      <Switch
        // {...methods.register(props.name, props.options)}
        checked={Boolean(field.value)}
        onChange={field.onChange}
        onBlur={field.onBlur}
        className={classNames(
          field.value ? 'bg-blue-600' : 'bg-gray-500',
          'relative inline-flex mt-2 h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2'
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            field.value ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
          )}
        />
      </Switch>
    </Switch.Group>
    //   )}
    // />
  );
}
