import { Link } from "react-router-dom";
import { useOrganizations } from "../../hooks/useOrganizations/index.js";

export default function AllProjects() {
  const orgs = useOrganizations({ variables: {} });
  if (orgs.isLoading) {
    return <h3>Loading...</h3>
  }
  return (
    <div className="grid grid-col-1">
      <p>Projects Page</p>
      <Link to="./create" className="button">Create New Project</Link>
      <p>{JSON.stringify(orgs.data, null, 4)}</p>
    </div>
  );
}
