'use client';

import { Transition } from '@headlessui/react';
import {
  BuildingLibraryIcon,
  ChartBarIcon,
  HomeIcon,
} from '@heroicons/react/24/outline';
import type { Project } from 'database';
import { Fragment, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useProjects } from '../../hooks/useProjects/index.js';
import { OrganizationContext } from '../providers.js';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const activeClasses =
  'text-blue-700 dark:text-blue-400 border-blue-700 dark:border-blue-400';
const inactiveClasses =
  'hover:text-blue-700 dark:hover:text-blue-400 hover:border-blue-700 dark:hover:border-blue-400';

interface MenuItemProps {
  name: string;
  href: string;
  current: boolean;
  className?: string;
  icon?: React.FunctionComponent<any>;
}

const MenuItem = (props: MenuItemProps) => {
  return (
    <li key={props.name}>
      <Link
        to={props.href}
        className={classNames(
          props.current ? activeClasses : inactiveClasses,
          props.className || '',
          'group flex gap-x-3 p-2 leading-6'
        )}
      >
        {props.icon ? (
          <props.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
        ) : (
          ''
        )}
        {props.name}
      </Link>
    </li>
  );
};

interface ProjectMenuProps {
  project: Project;
  pathname: string;
}

const ProjectMenu = (props: ProjectMenuProps) => {
  const prefix = `/organizations/${props.project.organizationId}/projects/${props.project.id}`;
  const settings = `${prefix}/settings`;
  const overview = `${prefix}/overview`;
  const taskLogic = `${prefix}/tasks`;
  const library = `${prefix}/library`;
  return (
    <>
      <h5 className="mb-2 mt-4 font-semibold text-gray-100">
        <Link to={settings}>{props.project.name}</Link>
      </h5>
      <ul className="">
        <MenuItem
          name="Plan Overview"
          current={props.pathname.startsWith(overview)}
          href={overview}
          className="border-l pl-4"
        />
        <MenuItem
          name="Task Logic"
          current={props.pathname.startsWith(taskLogic)}
          href={taskLogic}
          className="border-l pl-4"
        />
        <MenuItem
          name="Asset Library"
          current={props.pathname.startsWith(library)}
          href={library}
          className="border-l pl-4"
        />
      </ul>
    </>
  );
};

const Menu = () => {
  const {pathname} = useLocation();
  const orgCtx = useContext(OrganizationContext);
  const projectsQuery = useProjects({
    variables: {
      organizationId: orgCtx.organization?.id,
    },
    enabled: !!orgCtx.organization?.id,
  });

  if (!projectsQuery.isSuccess) {
    return <h3>Loading</h3>;
  }
  const { projects } = projectsQuery.data;
  return (
    <div className="flex grow flex-col overflow-y-auto px-6 py-5">
      <nav className="flex flex-1 flex-col">
        <ul className="">
          <MenuItem
            name="Command Center"
            current={pathname === '/'}
            href="/"
            icon={HomeIcon}
            className="mb-2"
          />
          <MenuItem
            name="Personal Library"
            current={pathname === '/library'}
            href="/library"
            icon={BuildingLibraryIcon}
            className="mb-2"
          />
          <MenuItem
            name="Projects"
            current={pathname.endsWith('/projects')}
            href={`/organizations/${orgCtx.organization.id}/projects`}
            icon={ChartBarIcon}
            className="mb-8"
          />
          {projects.map((p) => (
            <li key={p.id} className="mt-6 p-2">
              <ProjectMenu project={p} pathname={pathname} />
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

interface LeftSidebarNavProps {
  sidebarOpen: boolean;
}

export default function LeftSidebarNav(props: LeftSidebarNavProps) {
  return (
    <>
      <Transition
        as={Fragment}
        show={props.sidebarOpen}
        enter="transition ease-in-out duration-300 transform"
        enterFrom="-translate-x-full"
        enterTo="translate-x-0"
        leave="transition ease-in-out duration-300 transform"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
        // className="fixed inset-0 top-header bg-base-light dark:bg-slate-900"
      >
        {/* <div className="z-30 h-full w-screen"> */}
        {/* <Dialog
          as="div"
          className="relative z-30"
          onClose={props.setSidebarOpen}
        > */}
        {/* <Transition.Child
            as={Fragment}
            enter="transform transition ease-in-out duration-250 sm:duration-500"
            enterFrom="translate-x-full"
            enterTo="translate-x-100"
            leave="transform transition ease-in-out duration-250 sm:duration-500"
            leaveFrom="translate-x-100"
            leaveTo="translate-x-full"
          >
            <div className="fixed inset-0 top-header bg-base-light dark:bg-slate-900" />
          </Transition.Child> */}

        <div className="fixed inset-0 top-header flex grow">
          {/* <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        > */}
          <div className="flex w-full flex-1 flex-col bg-base-light dark:bg-slate-900">
            {/* <Dialog.Panel className="relative flex w-full flex-1 flex-col"> */}
            {/* <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="align-center absolute top-0 flex h-header">
                    <button
                      type="button"
                      className="p-5"
                      onClick={() => props.setSidebarOpen(!props.sidebarOpen)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child> */}
            {/* <div className="h-header p-2">
                  <Logo type="full" />
                </div> */}
            <Menu />
            {/* </Dialog.Panel> */}
          </div>
          {/* </Transition.Child> */}
        </div>
        {/* </Dialog> */}
        {/* </div> */}
      </Transition>
      <div className="hidden lg:block">
        <Menu />
      </div>
    </>
  );
}
