import ProjectForm from '../../components/forms/project-form.js';
import { useCreateProject } from '../../hooks/useCreateProject/index.js';
import { useQueryClient } from '@tanstack/react-query';
import { useProjects } from '../../hooks/useProjects/index.js';
import { useNavigate, useParams } from 'react-router-dom';
import { useProject } from '../../hooks/useProject/index.js';
import { useUpdateProject } from '../../hooks/useUpdateProject/index.js';
import { useDeleteProject } from '../../hooks/useDeleteProject/index.js';

type PathParams = {
  organizationId: string;
  projectId: string;
}

export default function EditProject() {
  const navTo = useNavigate();
  const queryClient = useQueryClient();
  const params = useParams<PathParams>();
  if (!params.organizationId || !params.projectId) {
    throw new Error('invalid url');
  }
  const projResp = useProject({
    variables: {
      organizationId: params.organizationId,
      projectId: params.projectId,
    },
  });

  const updateProject = useUpdateProject({
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [useProjects.getPrimaryKey()],
      });
      navTo(`/organizations/${data.project.organizationId}/projects/${data.project.id}/overview`);
    },
  });

  const deleteProject = useDeleteProject({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [useProjects.getPrimaryKey()],
      });
      navTo('/');
    },
  });
  if (!projResp.isSuccess) {
    return <h3>Loading project details...</h3>;
  }
  console.log(projResp);
  return (
    <div className="w-full">
      <ProjectForm organizationId={params.organizationId} project={projResp.data?.project} onUpdate={updateProject.mutateAsync} onDelete={deleteProject.mutateAsync} />
    </div>
  );
}
