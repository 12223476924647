import type { User } from '@prisma/client';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useContext, useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import TextareaAutosize from 'react-textarea-autosize';

// import { laloHistory, messageLaLo } from '@/app/projects/client';
import { LaLoContext } from '../providers.js';
import { useLaloHistory } from '@/hooks/useLaloHistory/index.js';
import { useParams } from 'react-router-dom';
import { useLaloSendMessage } from '@/hooks/useLaloSendMessage/index.js';

const jane = {
  firstName: 'Jane',
  lastName: 'Doe',
  profilePicture:
    'https://images.unsplash.com/photo-1553514029-1318c9127859?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=100&h=100&q=80',
};
const lalo = {
  firstName: 'LaLo',
  lastName: '',
  profilePicture: '/lalo.png',
};

enum MessageDirection {
  Incoming = 'incoming',
  Outgoing = 'outgoing',
}

const initialMessages = [
  {
    text: 'I am LaLo, an expert in product marketing and go-to-market frameworks. I can help marketing professionals with their questions in this area, such as developing effective marketing strategies, identifying target audiences, creating compelling messaging, and optimizing product launches.',
    author: lalo,
    direction: MessageDirection.Incoming,
    createdAt: new Date().toISOString(),
  },
];

interface LaLoMessageProps {
  author: Pick<User, 'profilePicture' | 'firstName' | 'lastName'>;
  text: string;
  direction: MessageDirection;
}
const messageClasses: {
  div: { [key in MessageDirection]: string };
  p: { [key in MessageDirection]: string };
} = {
  div: {
    [MessageDirection.Incoming]: 'flex-row-reverse',
    [MessageDirection.Outgoing]: '',
  },
  p: {
    [MessageDirection.Incoming]: 'dark:bg-zinc-700',
    [MessageDirection.Outgoing]: 'dark:bg-gray-700',
  },
};
function LaLoMessage(props: LaLoMessageProps) {
  return (
    <div
      className={`flex w-full justify-end ${
        messageClasses.div[props.direction]
      }`}
    >
      <div
        className={`prose prose-slate mx-3 max-w-none rounded-md border border-gray-500/50 p-3 dark:prose-invert ${
          messageClasses.p[props.direction]
        }`}
      >
        <ReactMarkdown
        // components={{
        //   ol: ({ node, ...rest }) => (
        //     <ol className="mb-2 ml-4 list-decimal" {...rest} />
        //   ),
        //   p: ({ node, ...rest }) => <p className="mb-2" {...rest} />,
        // }}
        >
          {props.text}
        </ReactMarkdown>
      </div>
      <img
        className="mx-3 my-1 h-14 w-14 self-end rounded-full"
        src={props.author.profilePicture}
        alt={`${props.author.firstName} ${props.author.lastName}`}
      />
    </div>
  );
}

type LaloPathParams = {
  organizationId: string;
  projectId: string;
  taskId?: string;
}

export default function LaLoMain() {
  const laloCtx = useContext(LaLoContext);
  const [messages, setMessages] = useState([...initialMessages]);
  const [pendingMessage, setPendingMessage] = useState('');
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    setTimeout(() => messagesEndRef.current?.scrollIntoView(false), 250);
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);
  const laloHistoryRes = useLaloHistory({
    variables: {
      path: laloCtx.path,
     },
    enabled: !!laloCtx.path,
  });
  const messageLalo = useLaloSendMessage({
    onSuccess: (data) => {
      setMessages([
        ...messages,
        {
          author: lalo,
          direction: MessageDirection.Incoming,
          text: data.reply,
          createdAt: new Date().toISOString(),
        },
      ]);
    },
  });
  // const useMessageLaLo = useMutation({
  //   mutationFn: messageLaLo,
  //   onSuccess: (data) => {
  //     setMessages([
  //       ...messages,
  //       {
  //         author: lalo,
  //         direction: MessageDirection.Incoming,
  //         text: data.reply,
  //         createdAt: new Date().toISOString(),
  //       },
  //     ]);
  //   },
  // });

  useEffect(() => {
    if (!laloHistoryRes.isSuccess) return;
    // if (!laloHistoryRes.data.messages.length) return;
    setMessages(
      laloHistoryRes.data.messages.map((m: any) => {
        const isAI = m.id.indexOf('AIMessage') > 0;
        return {
          author: isAI ? lalo : jane,
          text: m.kwargs.content,
          direction:
            isAI
              ? MessageDirection.Incoming
              : MessageDirection.Outgoing,
        };
      })
    );
  }, [laloHistoryRes.isSuccess]);

  const handleSend = () => {
    setMessages([
      ...messages,
      {
        author: jane,
        direction: MessageDirection.Outgoing,
        text: pendingMessage,
        createdAt: new Date().toISOString(),
      },
    ]);
    messageLalo.mutate({
      path: laloCtx.path,
      input: pendingMessage,
    });
    setPendingMessage('');
  };

  return (
    <div className="grid h-full grid-cols-1 grid-rows-[max-content_1fr_min-content]">
      <div className="p-4">
        <h3 className="text-center text-2xl">LaLo</h3>
      </div>
      <div className="grid grow auto-rows-max grid-cols-1 gap-y-4 overflow-y-auto px-8">
        {messages.map((m) => (
          <LaLoMessage
            key={m.createdAt}
            text={m.text}
            author={m.author}
            direction={m.direction}
          />
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div className="flex shrink-0 grow-0 flex-col p-4">
        <TextareaAutosize
          // cacheMeasurements
          minRows={3}
          maxRows={15}
          className="resize-none rounded-md dark:bg-slate-800"
          placeholder="send a message"
          value={pendingMessage}
          onChange={(e) => {
            setPendingMessage(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              handleSend();
            }
          }}
        />
        <button
          type="submit"
          className="mt-1 rounded-md bg-blue-900 p-2"
          onClick={handleSend}
        >
          Send
        </button>
      </div>
    </div>
  );
}
