import { createQuery, createMutation } from 'react-query-kit';

import { useProjects } from '../useProjects/index.js';
import { client } from '../api.js';
import { useMutation } from '@tanstack/react-query';

export interface CreateProjectMutationVariables {
  organizationId: string;
  project: unknown;
}

export const useCreateProject = createMutation<any, CreateProjectMutationVariables>(
{
  mutationFn: async (vars) => {
    const proj = await client.post(`/v1/organizations/${vars.organizationId}/projects`, vars.project);
    return proj.data;
  },
})
