import { createQuery } from 'react-query-kit';

import { useOrganizations } from '../useOrganizations/index.js';
import { client } from '../api.js';

type Variables = { organizationId: string };

export const useOrganization = createQuery<unknown, Variables, Error>({
  primaryKey: useOrganizations.getPrimaryKey(),
  queryFn: async (ctx) => {
    const resp = await client.get(`/v1/organizations/${ctx.queryKey[1].organizationId}`);
    return resp.data;
  },
});
