import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { PlusIcon } from '@heroicons/react/24/solid';
import { useQueryClient } from '@tanstack/react-query';
import { Link, useParams } from 'react-router-dom';
import { useUpdateTask } from '../../../hooks/useUpdateTask/index.js';
import { useProject } from '../../../hooks/useProject/index.js';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

type PageParams = {
  organizationId: string;
  projectId: string;
}

export default function ProjectTasks() {
  const params = useParams<PageParams>() as PageParams;
  const queryClient = useQueryClient();
  const updateTask = useUpdateTask({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: useProject.getKey(params),
      });
    },
  });
  const projectResp = useProject({
    variables: {
      organizationId: params.organizationId,
      projectId: params.projectId,
    },
  });

  if (!projectResp.isSuccess) {
    return <h3>Loading... (tasks)</h3>;
  }
  if (!projectResp.data?.project) {
    return <h3>Error: No such project</h3>;
  }

  // console.log('usersById', usersById);
  // const tasksById = listToIdMap(tasks);

  // const checkDependencyCompletion = (depIds: number[] = []) => {
  //   let done = true;
  //   for (const depId of depIds) {
  //     done = done && !!tasksById[depId]?.completed;
  //   }
  //   return done;
  // };

  return (
    <div className="flex flex-1 flex-col">
      {/* <div className="md:flex md:items-center md:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="text-2xl font-bold leading-7 sm:truncate sm:text-3xl sm:tracking-tight">
              Task Logic
            </h2>
          </div>
          <div className="mt-4 flex md:ml-4 md:mt-0">
            <button
              type="button"
              className="inline-flex items-center rounded-md bg-orange/80 px-3 py-2 font-semibold shadow-sm hover:bg-orange/90"
              onClick={() => {
                API._Refresh();
                queryClient.invalidateQueries();
              }}
            >
              Reset State
            </button>
          </div>
        </div> */}

      {/* <div className="border-b border-gray-500/80">
          <div className="sm:flex sm:items-baseline">
            <h3 className="text-2xl font-semibold leading-6">Task Logic</h3>
            <div className="mt-4 sm:ml-10 sm:mt-0">
              <nav className="-mb-px flex space-x-8">
                {phases.map((phase) => (
                  <a
                    key={phase.name}
                    href={phase.href}
                    className={classNames(
                      phase.current
                        ? 'border-grey-500'
                        : 'border-transparent hover:border-gray-500',
                      'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium'
                    )}
                    aria-current={phase.current ? 'page' : undefined}
                  >
                    {phase.name} (
                    {
                      tasks.filter(
                        (t) => t.phaseId === phase.id && t.completed
                      ).length
                    }
                    /{tasks.filter((t) => t.phaseId === phase.id).length})
                  </a>
                ))}
              </nav>
            </div>
          </div>
        </div> */}
      <div className="grid flex-1 grid-cols-1 gap-4 xl:grid-cols-4">
        {projectResp.data.project.phases.sort((a, b) => a.order - b.order).map((phase) => (
          <div key={phase.name} className="flex flex-col">
            <div className="flex justify-between">
              <h3 className="text-xl">{phase.name}</h3>
              <PlusIcon className="w-6" />
            </div>
            <div className="mt-2 grow rounded-md bg-gray-500/25">
              <ul className="m-3 grid grid-cols-1 gap-2 sm:gap-3">
                {phase.tasks
                  .filter((t) => !t.parentTaskId)
                  .sort((a, b) => a.order - b.order)
                  .map((task) => (
                    <li
                      key={task.name}
                      className="col-span-1 flex rounded-md shadow-sm"
                      // style={{
                      //   opacity: checkDependencyCompletion(task.dependsOn)
                      //     ? 1
                      //     : 0.3,
                      // }}
                    >
                      <div
                        className={classNames(
                          task.completed ? 'bg-green-600' : 'bg-gray-500',
                          'w-3 shrink-0 rounded-l-md'
                        )}
                      />
                      <div className="grid grow grid-rows-[1fr_min-content] gap-2 rounded-r-md border-y border-r border-gray-600 bg-gray-300 p-4 dark:bg-slate-700">
                        <div className="flex flex-1 items-center justify-between">
                          {/* <div className="flex-shrink-0">
                              <button
                                type="button"
                                className="-ml-2 inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent text-white/60 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:ring-offset-1"
                              >
                                <span className="sr-only">Mark Complete</span>
                                <CheckCircleIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </button>
                            </div> */}
                          <div className="flex-1">
                            <Link
                              to={`/organizations/${params.organizationId}/projects/${params.projectId}/tasks/${task.id}/details`}
                            >
                              {task.name}
                            </Link>
                            {/* <p className="">{task.members} Members</p> */}
                          </div>
                          <div
                            className={classNames(
                              '-mt-1 h-8 w-8 shrink-0 -mr-1'
                            )}
                          >
                            <button
                              type="button"
                              className={classNames(
                                'flex h-8 w-8 items-center justify-center rounded-full bg-transparent focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
                                task.completed ? 'hidden' : ''
                              )}
                              onClick={() => {
                                updateTask.mutateAsync({
                                  organizationId: params.organizationId,
                                  projectId: params.projectId,
                                  taskId: task.id,
                                  patch: { completed: !task.completed },
                                });
                              }}
                            >
                              <span className="sr-only">Mark Complete</span>
                              <CheckCircleIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                        <div className='h-10 flex align-center'>

                            <div className="flex items-center text-sm">
                              <div className="shrink-0">
                                {task.assignee ? (
                                  <img
                                    className="h-10 w-10 rounded-full"
                                    src={task.assignee.profilePicture}
                                    alt={`${task.assignee.firstName} ${task.assignee.lastName}`}
                                  />
                                ) : null}
                              </div>
                              <div className="min-w-0 flex-1">
                                {task.assignee ? (
                                  <a href="." className="focus:outline-none">
                                    <p className="ml-2">
                                      {task.assignee.firstName}{' '}
                                      {task.assignee.lastName}
                                    </p>
                                  </a>
                                ) : <p className='opacity-50'>unassigned</p>}

                              </div>
                            </div>
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
