import { createMutation } from 'react-query-kit';

import { client } from '../api.js';

export interface UpdateProjectMutationVariables {
  organizationId: string;
  projectId: string;
  patch: unknown;
}

export const useUpdateProject = createMutation<any, UpdateProjectMutationVariables>(
{
  mutationFn: async (vars) => {
    const proj = await client.patch(`/v1/organizations/${vars.organizationId}/projects/${vars.projectId}`, vars.patch);
    return proj.data;
  },
})
