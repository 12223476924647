import CreateProjectForm from '../../components/forms/project-form.js';
import { useCreateProject } from '../../hooks/useCreateProject/index.js';
import { useQueryClient } from '@tanstack/react-query';
import { useProjects } from '../../hooks/useProjects/index.js';
import { useNavigate, useParams } from 'react-router-dom';

export default function CreateProject() {
  const navTo = useNavigate();
  const queryClient = useQueryClient();
  const params = useParams();

  const cp = useCreateProject({
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [useProjects.getPrimaryKey()],
      });
      navTo(`/organizations/${data.project.organizationId}/projects/${data.project.id}/overview`);
    },
  });
  if (!params.organizationId) {
    return <h3>Error: Page must be rendered in organization context.</h3>;
  }
  return (
    <div className="w-full">
      <CreateProjectForm organizationId={params.organizationId} onCreate={cp.mutateAsync} />
    </div>
  );
}
