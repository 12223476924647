import { Auth0Provider } from '@auth0/auth0-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import type { ReactNode } from 'react';
import { createContext, useMemo, useState } from 'react';
import { ThemeProvider } from './theme-provider.js';

export const LaLoContext = createContext({
  path: '',
  setPath: (_: string) => {},
});

export const OrganizationContext = createContext({
  organization: {},
  setOrganization: (_: object) => {},
});

export default function Providers({ children }: { children: ReactNode }) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            // suspense: true,
            // retryDelay: (attemptIndex) =>
            //   Math.min(1000 * 2 ** attemptIndex, 30000),
            staleTime: 1000 * 10,
          },
        },
      })
  );
  const [laloPath, setLaloPath] = useState('');
  const lpValue = useMemo(
    () => ({ path: laloPath, setPath: setLaloPath }),
    [laloPath]
  );

  const [organization, setOrganization] = useState({});
  const orgValue = useMemo(
    () => ({ organization, setOrganization }),
    [organization]
  );

  return (
    <ThemeProvider defaultTheme="dark" storageKey="ll-theme">
    <Auth0Provider
      domain={import.meta.env.VITE_AUTH0_ISSUER_BASE_URL}
      clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: import.meta.env.VITE_APP_BASE_URL,
      }}
    >
    <OrganizationContext.Provider value={orgValue}>
      <LaLoContext.Provider value={lpValue}>
        <QueryClientProvider client={queryClient}>
          {children}
        </QueryClientProvider>
      </LaLoContext.Provider>
    </OrganizationContext.Provider>
    </Auth0Provider>
    </ThemeProvider>
  );
}
