import type { RegisterOptions } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '../ui/form.js';
import { Input } from '../ui/input.js';

interface TextInputProps {
  name: string;
  label: string;
  options: RegisterOptions;
  placeholder?: string;
  description?: string;
}

export default function TextInput(props: TextInputProps) {
  // console.log(
  //   'TextInput',
  //   props.name,
  //   JSON.stringify(props),
  //   Object.keys(props)
  // );
  const methods = useFormContext();
  return (
    <FormField
      control={methods.control}
      name={props.name}
      rules={props.options}
      render={({ field }) => (
        <FormItem>
          <FormLabel>
            {props.label}
            {!props.options.required ? (
              <span className="text-sm leading-6" id={`${props.name}-optional`}>
                Optional
              </span>
            ) : (
              ''
            )}
          </FormLabel>
          <FormControl>
            <Input placeholder={props.placeholder} {...field} />
          </FormControl>
          <FormDescription>
            {props.description}
          </FormDescription>
          <FormMessage />
        </FormItem>
      )}
    />
  )
  // }
  // return (
  //   <div>
  //     <div className="flex justify-between">
  //       <label
  //         htmlFor={props.name}
  //         className="block font-medium leading-6 text-base"
  //       >
  //         {props.label}
  //       </label>
  //       {!props.options.required ? (
  //         <span className="text-sm leading-6" id={`${props.name}-optional`}>
  //           Optional
  //         </span>
  //       ) : (
  //         ''
  //       )}
  //     </div>
  //     <div className="mt-2">
  //       <input
  //         {...methods.register(props.name, props.options)}
  //         type="text"
  //         id={props.name}
  //         className="block w-full rounded-md bg-slate-800 py-1.5 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-300 dark:focus:bg-slate-800 sm:text-lg sm:leading-7"
  //         placeholder={props.placeholder}
  //         aria-describedby={
  //           props.options.required ? '' : `${props.name}-optional`
  //         }
  //       />
  //     </div>
  //   </div>
  // );
}
