import { createQuery } from 'react-query-kit';

import { client } from '../api.js';

// import type { GetMeResponse } from '@/app/api/me/route';

export const useMe = createQuery({
  primaryKey: `me`,
  queryFn: async () => {
    const resp = await client.get('/v1/me');
    return resp.data;
  },
});
