
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import * as React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './index.css';
import './App.css';
import './styles/quill-theme.css';
import Home from './pages/home.js';
import MainLayout from './components/layout/main.js';
import { client } from './hooks/api.js';
import AllProjects from './pages/projects/all.js';
import CreateProject from './pages/projects/create.js';
import { OrganizationContext } from './components/providers.js';
import { useMe } from './hooks/useMe/index.js';
import EditProject from './pages/projects/settings.js';
import ProjectTasks from './pages/projects/tasks/board.js';
import ProjectTaskDetails from './pages/projects/tasks/detail.js';

const App: React.FC = () => {
  const a0 = useAuth0();

  React.useEffect(() => {
    (async () => {
      try {
        const token = await a0.getAccessTokenSilently({ detailedResponse: true });
        console.log(token);
        client.defaults.headers['authorization'] = `Bearer ${token.id_token}`;
      } catch (e) {
        console.error(e);
      }
    })();
  }, [a0.getAccessTokenSilently]);
  const meQuery = useMe({
    variables: {},
    enabled: a0.isAuthenticated && !!client.defaults.headers['authorization'],
  });
  const orgCtx = React.useContext(OrganizationContext);
  React.useEffect(
    () =>
      orgCtx.setOrganization(meQuery.data?.user.organizations[0]?.organization),
    [meQuery.data?.user.organizations]
  );

  if (a0.isLoading) {
    return <h1>Auth Loading...</h1>;
  } else if (a0.error) {
    return <><h1>error</h1><p>{a0.error.message}</p></>
  }
  const RouteTree = withAuthenticationRequired(() =>
    <Routes>
      <Route path="/" element={<Home />}/>
      <Route path="/organizations/:organizationId/projects" element={<AllProjects />}/>
      <Route path='/organizations/:organizationId/projects/create' element={<CreateProject />} />
      <Route path='/organizations/:organizationId/projects/:projectId/settings' element={<EditProject />} />
      <Route path='/organizations/:organizationId/projects/:projectId/tasks' element={<ProjectTasks />} />
      <Route path='/organizations/:organizationId/projects/:projectId/tasks/:taskId/details' element={<ProjectTaskDetails />} />
    </Routes>
  )
  return (
      <BrowserRouter>
        <MainLayout>
          <RouteTree />
        </MainLayout>
      </BrowserRouter>
  );
};

export default App;
