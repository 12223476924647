import type { ReactNode } from 'react';
import { useState } from 'react';

import LaLoMain from '../lalo/main.js';
import Header from './header.js';
import LeftSidebarNav from './left-sidebar.js';
import { useLocation } from 'react-router-dom';

export default function MainLayout({ children }: { children: ReactNode }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { pathname } = useLocation();
  return (
    <div className="grid min-h-full grid-cols-1 grid-rows-[min-content_1fr] lg:grid-cols-[max-content_1fr_min-content]">
      <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      <aside className="sticky top-0 -mt-header w-72 pt-header lg:row-span-1 lg:row-start-2 lg:block lg:h-[calc(100vh-theme(height.header))]">
        <LeftSidebarNav sidebarOpen={sidebarOpen} />
      </aside>

      <main className="flex min-h-[calc(100vh-theme(height.header))] px-12 py-8">{children}</main>

      <aside
        className={[
          'sticky top-0 -mt-header h-[calc(100vh-theme(height.header))] w-[50vw] pt-header',
          pathname.match(/\/tasks\/[a-z0-9]+/) ? '' : 'hidden',
        ].join(' ')}
      >
        <LaLoMain />
      </aside>
    </div>
  );
}
