import { createMutation } from 'react-query-kit';

import { client } from '../api.js';

export interface LaloSendMessageMutationVariables {
  path: string;
  input: string;
}

export const useLaloSendMessage = createMutation<any, LaloSendMessageMutationVariables>(
{
  mutationFn: async (vars) => {
    const resp = await client.post(vars.path, { input: vars.input });
    return resp.data;
  },
})
