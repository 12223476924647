import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { LaLoContext } from '../../../components/providers.js';
import { useUpdateTask } from '../../../hooks/useUpdateTask/index.js';
import { useProject } from '../../../hooks/useProject/index.js';
import TaskDetailForm from '../../../components/forms/task-form.js';
import { useOrganization } from '../../../hooks/useOrganization/index.js';


export default function TaskDetail() {
  const params = useParams();
  console.log('TaskDetail', params.taskId);
  const laloCtx = useContext(LaLoContext);
  useEffect(
    () =>
      laloCtx.setPath(
        `/v1/organizations/${params.organizationId}/projects/${params.projectId}/tasks/${params.taskId}/lalo`
      ),
    [params.organizationId, params.projectId, params.taskId]
  );
  const queryClient = useQueryClient();
  const updateTask = useUpdateTask({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['projects', params.projectId],
      });
    },
  });
  const { projectId, taskId } = params;
  const projectResp = useProject({ variables: params });
  const orgResp = useOrganization({ variables: { organizationId: params.organizationId } });
  // console.log(projectResp);
  if (!projectResp.isSuccess || !orgResp.isSuccess) {
    return <h3>Loading... (single-task)</h3>;
  }
  const { organization } = orgResp.data;
  const { project } = projectResp.data;
  let task;
  // const tasks = [];
  // const users = [];
  for (const phase of project.phases) {
    for (const t of phase.tasks) {
      if (t.id === taskId) task = t;
    }
  }
  // const task = tasks.find((t) => t.id === taskId);
  if (!project || !task) {
    return <h3>Error: No such project or task</h3>;
  }
  return (
    <div className="w-full">
      <TaskDetailForm
        project={project}
        task={task}
        users={organization.users}
        onSubmit={updateTask.mutateAsync}
      />
    </div>
  );
}
