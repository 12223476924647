import { createQuery } from 'react-query-kit';
import { client } from '../api.js';

interface UseProjectsVariables {
  organizationId: string;
}

export const useProjects = createQuery<unknown, UseProjectsVariables, Error>({
  primaryKey: 'projects',
  queryFn: async (ctx) => {
    if (!ctx.queryKey[1]?.organizationId) {
      throw new Error ('no active organization');
    }
    const resp = await client.get(`/v1/organizations/${ctx.queryKey[1].organizationId}/${ctx.queryKey[0]}`);
    return resp.data;
  },
});
