import { createQuery } from 'react-query-kit';

import { useProjects } from '../useProjects/index.js';
import { client } from '../api.js';

type Variables = { organizationId: string; projectId: string };

export const useProject = createQuery<unknown, Variables, Error>({
  primaryKey: useProjects.getPrimaryKey(),
  queryFn: async (ctx) => {
    if (!ctx.queryKey[1]?.organizationId) {
      throw new Error ('no active organization');
    }
    const resp = await client.get(`/v1/organizations/${ctx.queryKey[1].organizationId}/${ctx.queryKey[0]}/${ctx.queryKey[1].projectId}`);
    return resp.data;
  },
});
