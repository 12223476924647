import type { UseMutateAsyncFunction } from '@tanstack/react-query';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';

import TextInput from './text-input.js';
import { CreateProjectMutationVariables } from '../../hooks/useCreateProject/index.js';
import { UpdateProjectMutationVariables } from '../../hooks/useUpdateProject/index.js';
import { DeleteProjectMutationVariables } from '../../hooks/useDeleteProject/index.js';
import { Form } from '../ui/form.js';
import { Button } from '../ui/button.js';
import { Card, CardContent, CardFooter, CardHeader } from '../ui/card.js';

interface IFormInput {
  id?: string;
  name: string;
}

interface ProjectFormProps {
  organizationId: string;
  project?: IFormInput;
  onCreate?: UseMutateAsyncFunction<any, unknown, CreateProjectMutationVariables, unknown>;
  onUpdate?: UseMutateAsyncFunction<any, unknown, UpdateProjectMutationVariables, unknown>;
  onDelete?: UseMutateAsyncFunction<any, unknown, DeleteProjectMutationVariables, unknown>;
}

export default function ProjectForm(props: ProjectFormProps) {
  const formMethods = useForm<IFormInput>({
    mode: 'onChange',
    defaultValues: props.project,
  });
  console.log(props.project);
  const onSubmitInternal: SubmitHandler<IFormInput> = async (input) => {
    if (props.project?.id && props.onUpdate) {
      // this is an update
      await props.onUpdate({
        organizationId: props.organizationId,
        projectId: props.project.id,
        patch: input,
      });
    } else if (props.onCreate) {
      // this is a create
      await props.onCreate({
        organizationId: props.organizationId,
        project: input,
      });
    }
  };
  const onDeleteInternal: SubmitHandler<{}> = async () => {
    if (props.project?.id && props.onDelete) {
      await props.onDelete({
        organizationId: props.organizationId,
        projectId: props.project.id,
      });
    }
  }
  return (
    <Form {...formMethods}>
      <form
        className="space-y-6 max-w-md"
        onSubmit={formMethods.handleSubmit(onSubmitInternal)}
      >
        <Card>
          <CardHeader></CardHeader>
          <CardContent>
                <TextInput name="name" label="Name" options={{ required: true }} />
          </CardContent>
          <CardFooter>
            <div className="flex w-full flex-row-reverse max-w-md justify-between">
              <Button
                className="self-end"
                type="submit"
                disabled={
                  formMethods.formState.isSubmitting
                }>Save</Button>
              {props.onDelete ?
                <Button
                  disabled={
                    formMethods.formState.isSubmitting
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onDeleteInternal({}, e);
                  }}
                  variant="destructive"
                  >
                    Delete Immediately
                  </Button>
                : null }
              </div>
      </CardFooter>
    </Card>
            </form>
          </Form>
  );
}
