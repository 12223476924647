import { Combobox } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { useMemo, useState } from 'react';
import type { RegisterOptions } from 'react-hook-form';
import { Controller, useFormContext } from 'react-hook-form';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

interface ComboBoxInputItem {
  id: string;
  label: string;
}

interface ComboBoxInputProps {
  name: string;
  label: string;
  items: ComboBoxInputItem[];
  options?: RegisterOptions;
}

export default function ComboBoxInput(props: ComboBoxInputProps) {
  // console.log(
  //   'ComboBoxInput',
  //   props.name,
  //   JSON.stringify(props),
  //   Object.keys(props)
  // );

  const [query, setQuery] = useState('');
  const itemsById = useMemo(() => {
    const byId: { [key: string]: ComboBoxInputItem } = {};
    for (const item of props.items) {
      byId[item.id] = item;
    }
    return byId;
  }, [props.items]);
  // const {
  //   field: { value, onChange },
  // } = useController(props);

  const filtered =
    query === ''
      ? props.items
      : props.items.filter((i) => {
          return i.label.toLowerCase().includes(query.toLowerCase());
        });
  // console.log(
  //   'ComboBoxInput',
  //   props.name,
  //   'filtered',
  //   JSON.stringify(filtered)
  // );

  const methods = useFormContext();
  return (
    <Controller
      control={methods.control}
      name={props.name}
      rules={props.options}
      render={({ field: { onChange, value } }) => {
        // console.log('ComboBoxInput', props.name, 'value', value);
        return (
          <Combobox as="div" value={value} onChange={onChange}>
            <Combobox.Label className="block font-medium leading-6">
              {props.label}
            </Combobox.Label>
            <div className="relative mt-2">
              <Combobox.Input
                className="w-full rounded-md bg-slate-800 py-2 pl-3 pr-10 shadow-sm focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:leading-6"
                onChange={(event) => setQuery(event.target.value)}
                displayValue={(i: string) => itemsById[i]?.label}
              />
              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Combobox.Button>

              {filtered.length > 0 && (
                <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md py-1 text-base text-black shadow-lg ring-1 ring-black focus:outline-none dark:bg-slate-700 dark:text-gray-300">
                  {filtered.map((item) => (
                    <Combobox.Option
                      key={item.id}
                      value={item.id}
                      className={({ active }) =>
                        classNames(
                          'relative cursor-default select-none py-2 pl-3 pr-9',
                          active ? 'bg-blue-600 text-white' : ''
                        )
                      }
                    >
                      {({ active, selected }) => (
                        <>
                          <span
                            className={classNames(
                              'block truncate',
                              selected ? 'font-semibold' : ''
                            )}
                          >
                            {item.label}
                          </span>

                          {selected && (
                            <span
                              className={classNames(
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                                active ? 'text-white' : 'text-blue-600'
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          )}
                        </>
                      )}
                    </Combobox.Option>
                  ))}
                </Combobox.Options>
              )}
            </div>
          </Combobox>
        );
      }}
    />
  );
}
